import React, {useEffect, useState, useRef} from 'react';
import { Helmet } from "react-helmet";
import { withPrefix, navigate } from 'gatsby';

import "../scss/main.scss";

import BuySteps from "../components/buySteps";
import InfoPopup from "../components/infoPopup";
import TicketsStep1 from "../components/TicketsStep1";
import TicketsStep2 from "../components/TicketsStep2";
// mock
import { defaultTicketsCount, step2DefaultValues } from "../mock/index";
import { ageCategories } from '../mock/ageCategories';
import { sendBuyTicketsRequest, sendPromocode } from '../api/index';
import { getDateKey, scrollToElem } from '../helpers';
import { FRONT_DOMAIN } from "../config";

export default () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};
/*
const TicketsPage = ( {pageContext: {contacts, modalContent}}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState();
    const [isNote, setIsNote] = useState();
    const [isMobile, setIsMobile] = useState();
    const [currentStep, setCurrentStep] = useState(1);
    const [ticketsCount, setTicketsCount] = useState(defaultTicketsCount);
    const [inputValues, setInputValues] = useState(step2DefaultValues);
    const [selectedDay, setSelectedDay] = useState();
    const [hasErrors, setErrors] = useState(false);
    const [minutes, setMinutes] = useState();
    const [hours, setHours] = useState();
    const [hoursOptions, setHoursOptions] = useState([]);
    const [promocode, setPromocode] = useState({verified: false, value: ''});
    const inputForm = useRef(null);
    
    const [ticketsData, setTicketsData] = useState({});
    // TODO - submitting - дисаблить кнопки пока форма отправляется и все такое. Нужны состояния в макете.
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        checkBreakpoints();
        
        window.addEventListener('resize', checkBreakpoints);
        return () => window.removeEventListener('resize', checkBreakpoints);
    }, []);
    
    useEffect(() => {
        isPopupOpen ? 
            document.body.classList.add('_popup-open') :
            document.body.classList.remove('_popup-open');
    }, [isPopupOpen]);
    
    useEffect(() => {
        if (hasErrors) {
            scrollToElem(inputForm.current.querySelector('._error'));
        }
    }, [hasErrors]);

    // methods
    const checkBreakpoints = () => {
        setIsDesktop(window.innerWidth > 1024);
        setIsMobile(window.innerWidth < 768);
        setIsNote(window.innerWidth > 1024 && window.innerWidth < 1440);
    }

    const isFormCorrect = () => {
        const regexp = {
            name: /.+/, // хотя бы один символ
            phone: /\+\d{3} \d{4,15}/, // +852 9999-9999
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // t@t.t где t - хотя бы один символ
        };
    
        const errors = [];
        Object.entries(inputValues).forEach(([name, data]) => {
            if(data.type === 'text' && data.name!=='qr_only' && !regexp[name].test(data.value) ) {
                addError(name);
            } else if(data.type === 'checkbox' && !data.checked) {
                addError(name)
            }
        });
    
        function addError(name) {
            errors.push(name);
        }

        const updatedFields = errors.reduce((acc, name) => {
            return  {               
                ...acc,
                [name]: {
                    ...acc[name],
                    error: errors.some(i => i === name),
                }
            }
        }, inputValues);

        setInputValues({
            ...updatedFields,
        });
        
        setErrors(errors.length);
        
        return !errors.length
    }

    const handleSubmit = () => {
        if(!isFormCorrect()) return

        const {name, phone, email, conditions} = inputValues;

        const data = {
            name: name.value, 
            phone: phone.value,
            email: email.value,
            conditions: conditions.checked,
            date: getDateKey(selectedDay),
            hours: hours.value,
            minutes: minutes.value,
            ticketsCount,
            promocode: promocode.isVerified ? promocode.value : '',
        }

        sendBuyTicketsRequest(data, setSubmitting);
    }

    async function handlePromocodeSubmit(e) {
        e.preventDefault();
        if (!promocode.value) {
            setPromocode({
                ...promocode,
                isVerified: false,
                error: false,
            });
            return false;
        }
        const res = await sendPromocode(promocode);
        // TODO непонятно что там должно приходить, поэтому пока так.

        console.log('promocode res', res);
        if(res && res.status == 1) {
            setPromocode({
                ...promocode,
                ...res,
                isVerified: true,
                error: false,
            })
        } else {
            setPromocode({
                ...promocode,
                isVerified: false,
                error: true,
            });
        }
    }

    // computed
    const total = () => {
        try {
            
            let availableDiscount = promocode.isVerified && promocode.status==1 ? parseInt(promocode.count_ticket) : 0;
            let sum = 0;
            Object.entries(ticketsCount).forEach(([name, value]) => {
                let price = ageCategories.find(i => i.name === name).price;
                if (price === '' || price === '-') price = 500;
                if (availableDiscount > 0) {
                    let discountCount = Math.min(value, availableDiscount);
                    console.log('d count', discountCount);
                    sum += discountCount * price/100*(100-parseFloat(promocode.discount));
                    availableDiscount -= discountCount;
                    value -= discountCount;
                }
                sum += value * price;
            });
            
            return sum;
        
        } catch {
            return 0;
        }
    
    }

    return (
        <>
            <Helmet>
                <title>Tickets | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <div className="tickets content-wrap">
                <div className="tickets__container">
                    <div className="tickets__steps">
                        <BuySteps setIsPopupOpen={setIsPopupOpen} currentStep={currentStep} isShort={false}/>
                    </div>

                    {currentStep === 1 && 
                        <TicketsStep1 
                            ticketsCount={ticketsCount}
                            total={total()}
                            hours={hours}
                            hoursOptions={hoursOptions}
                            minutes={minutes}
                            handlePromocodeSubmit={handlePromocodeSubmit}
                            promocode={promocode}
                            ticketsData={ticketsData}

                            setCurrentStep={setCurrentStep}
                            setTicketsCount={setTicketsCount}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            setHours={setHours}
                            setMinutes={setMinutes}
                            setHoursOptions={setHoursOptions}
                            setPromocode={setPromocode}
                            setTicketsData={setTicketsData}
                            
                            isMobile={isMobile} 
                            isNote={isNote}
                            isDesktop={isDesktop}
                        />
                    }
                    {currentStep === 2 && 
                        <TicketsStep2 
                            ticketsCount={ticketsCount}
                            total={total()}
                            inputValues={inputValues}
                            handleSubmit={handleSubmit}
                            submitting={submitting}
                            hours={hours}
                            minutes={minutes}
                            selectedDay={selectedDay}
                            promocode={promocode}
                            inputForm={inputForm}
                            handlePromocodeSubmit={handlePromocodeSubmit}
                            setPromocode={setPromocode}
                            setInputValues={setInputValues}
                            setCurrentStep={setCurrentStep}

                            isDesktop={isDesktop} 
                        />}
                        
                        <InfoPopup 
                            setIsPopupOpen={setIsPopupOpen} 
                            socialList={contacts.items}
                            modalContent={modalContent.items[0]}
                        />
                </div>
                
                
            </div>

            
        </>
    )
}

export default TicketsPage;*/
