import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import TicketInfoBlock from "../components/ticketInfoBlock";
import { ageCategories } from '../mock/ageCategories';
import smoothscroll from 'smoothscroll-polyfill';
import { scrollToElem } from '../helpers/index';

const TicketsStep2 = (props) => {
    const {isDesktop, ticketsCount, total, inputValues, setInputValues, handleSubmit, setCurrentStep, submitting, hours, minutes, selectedDay, promocode, setPromocode, handlePromocodeSubmit, inputForm} = props;

    useEffect(() => {
        smoothscroll.polyfill();
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        //(document.scrollingElement || document.documentElement).scrollTop = 0;
        scrollToElem(0);
    }, []);
    
    function handleChange(e) {
        const {name, value, checked} = e.target;
        const changes = e.target.type === 'checkbox' ? {checked: checked} : {value: value}; 
        
        setInputValues({
            ...inputValues,
            [name]: {
                ...inputValues[name],
                ...changes,
                error: false,
            } 
        });
    }
    
    const discount = () => {
        let full = Object.entries(ticketsCount).reduce((acc, [name, value]) => {
            if (value === 0) {
                return acc
            } else {
                return  acc + value * ageCategories.find(i => i.name === name).price
            }
        }, 0);

        return full-total;
    }

    return (
        <div className="tickets__grid _step-2">
            <div className="tickets__grid-section" ref={inputForm}>
                <div className="user-form">
                    <p className="user-form__title">ENTER YOUR DETAILS</p>
                    <div className="user-form__input-list">
                        <input className={`user-form__input ${inputValues.name.error ? '_error' : ''}`} type="text" name={inputValues.name.name} placeholder="Full name"
                            value={inputValues.name.value}
                            onChange={handleChange}
                        />
                        <InputMask className={`user-form__input ${inputValues.phone.error ? '_error' : ''}`} type="text" name={inputValues.phone.name} placeholder="Phone number"
                            mask="+999 999999999999"
                            value={inputValues.phone.value}
                            onChange={handleChange}
                            maskChar=""
                        />
                        <input className={`user-form__input ${inputValues.email.error ? '_error' : ''}`} type="text" name={inputValues.email.name} placeholder="Email address"
                            value={inputValues.email.value}
                            onChange={handleChange}
                        />
                        <p className="user-form__email-description">Digital ticket will be sent to your email</p>
                    </div>

                    {/* !isDesktop && <TicketInfoBlock/> */}

                </div>
                               
                <div className="user-form__info">
                    <p className="user-form__info-summary">Booking Summary: {selectedDay.toLocaleString('en-US', {weekday: 'long'})}, {selectedDay.toLocaleString('en-US', {month: 'long'})} {selectedDay.getDate()}, {hours.value}:{minutes.value<10?'0'+minutes.value:minutes.value}</p>
                    <ul className="user-form__tickets-info">
                        {Object.entries(ticketsCount).map(([name, count]) => {
                            if(!count) return ''
                            const {text, price} = ageCategories.find(i => i.name === name);
                            return (
                                <li className="user-form__info-item" key={name}>
                                    <span>{count} x {text}</span>
                                    <span>HK${price}</span>
                                </li>
                            )
                        })}
                        {
                            promocode.isVerified &&
                            <li className="user-form__info-item">
                                <span>Discount</span>
                                <span>-HK${discount()}</span>
                            </li>
                        }
                    </ul>
                </div>
                <div className="tickets__total">
                    <div className="tickets__total-text">
                        <span>Total</span>
                        <span>${total}</span>
                    </div>

                    <div className="tickets__promo">
                        <h3 className="tickets__promo-title">Promo code</h3>
                        <div className="tickets__promo-form-wrap">
                            <form
                                className={`tickets__promo-wrap ${promocode.value ? '_active' : ''} ${promocode.isVerified ? '_verified' : (promocode.error ? '_not-verified' : '')}`}
                                onSubmit={handlePromocodeSubmit}
                            >
                                <input className="tickets__promo-input" type="text" placeholder="Enter promo code"
                                       value={promocode.value}
                                       onChange={(e) => setPromocode({...promocode, value: e.target.value})}
                                       disabled={!selectedDay}
                                />
                                <button className="tickets__promo-submit" type="submit"
                                        disabled={!selectedDay || !promocode}
                                >Submit</button>
                            </form>
                            {promocode.isVerified && <div className="tickets__promo-discount">{promocode.comment || 'Code activated'}</div>}
                            {promocode.error && <div className="tickets__promo-error">Promocode expired or not found</div>}
                        </div>

                    </div>


                    <div className="tickets__step-nav">
                        <button className="tickets__back btn _black" type="button" onClick={() => setCurrentStep(1)}>back</button>
                        <button className="tickets__next btn" type="button" onClick={handleSubmit}>{submitting ? 'sending...' : 'pay now'}</button>
                    </div>
                    {/* todo */}
                </div>
                {/*<label className={`tickets__conditions form__checkbox ${inputValues.conditions.error ? '_error' : ''}`}>
                    <input type="checkbox"
                        name={inputValues.conditions.name} 
                        checked={inputValues.conditions.checked}
                        onChange={handleChange}
                    />
                    <span className="form__checkbox-text">
                        <span>I&nbsp;agree with the </span>
                        <a target="_blank" className="link" href="/docs/TC.pdf">terms &amp;&nbsp;conditions</a> and <a target="_blank" className="link" href="/docs/PP.pdf">privacy policy</a>
                    </span>
                </label>*/}
                <p className="user-form__privacy-text">By clicking 'Pay Now', you agree to the <a target="_blank" className="link" href="/docs/TC.pdf">terms &amp;&nbsp;conditions</a> and <a target="_blank" className="link" href="/docs/PP.pdf">privacy policy</a></p>
            </div>
            {/*isDesktop &&
                <div className="tickets__grid-section">
                    <TicketInfoBlock/>
                </div>
            */}
        </div>
        )
}

export default TicketsStep2